<template>
  <div class="page page-xranking-all-matching-cvs">
    <PageHeader
      back="/xranking/cvs"
      icon="mdi-magnify-scan"
      :title="`Matching - ${(cvMatch.length || noData) ? fullName : ''}`"
      class="pt-2"
    >
      <template slot="detail-info">
        <span
          class="page-xranking-all-matching-cvs__detail-info d-inline-flex ml-3"
          @click.stop=""
        >
          <v-icon class="align-center">mdi-eye</v-icon>
        </span>
      </template>
      <template slot="nav">
        <small><strong>{{ $tc('JOB.TITLE_COUNT', cvMatch.length) }}</strong></small>
      </template>
    </PageHeader> 

    <Loading :value="loading" color="#f0f0f0" zIndex="1" />
    <template v-if="cvMatch.length || noData">
      <div class = "mt-5 pl-4 pr-4">
        <div v-if="personalInfoOnMatch.position">
          <span
            class="page-xranking-all-matching-cvs__personal-info page-xranking-all-matching-cvs__position"
          >
            {{ personalInfoOnMatch.position }}
          </span>
        </div>
        <div v-if="personalInfoOnMatch.source_name">
          <v-icon
            class="page-xranking-all-matching-cvs__personal-info-icon pr-3"
            color="#201F35"
          >
            mdi-briefcase
          </v-icon>
          <span class="page-xranking-all-matching-cvs__personal-info">{{ personalInfoOnMatch.source_name }}</span>
        </div>
        <div v-if="experience">
          <v-icon
            class="page-xranking-all-matching-cvs__personal-info-icon pr-3"
            color="#201F35"
          >
            mdi-briefcase
          </v-icon>
          <span class="page-xranking-all-matching-cvs__personal-info">{{ experience }}</span>
        </div>
        <div v-for="({ school_name, level: { plaintext } }, index) in personalInfoOnMatch.educations" :key="index">
          <template v-if="school_name || plaintext">
            <v-icon
              class="page-xranking-all-matching-cvs__personal-info-icon pr-3"
              color="#201F35"
            >
              mdi-school
            </v-icon>
            <span class="page-xranking-all-matching-cvs__personal-info">{{ education(school_name, plaintext) }}</span>
          </template>
        </div>

        <div v-if="personalInfoOnMatch.city || personalInfoOnMatch.country">
          <v-icon
            class="page-xranking-all-matching-cvs__personal-info-icon pr-3"
            color="#201F35"
          >
            mdi-map-marker
          </v-icon>
          <span class="page-xranking-all-matching-cvs__personal-info">{{ location(personalInfoOnMatch.city, personalInfoOnMatch.country) }}</span>
        </div>

      </div>
    </template>
    <List
      v-if="cvMatch.length"
      :items="currentPageData"
      :count="cvMatch.length"
      :offset="offset"
      :limit="limit"
      :selectable="false"
      pagination
      class="mt-5"
      @page="changePage"
      @reload="validateRaOrg"
      @click="goToItem"
      @backToList="backToList"
      noButton
      actions
    >
      <template v-slot:header>
        <div class="page page-xranking-all-matching-cvs__list-header pr-4">
          <v-list-item-content class="page-xranking-all-matching-cvs__item-content-description">
            <v-label>
              {{ $t('COMMON.LABELS.TITLE') }}
            </v-label>
          </v-list-item-content>

          <v-list-item-content class="page-xranking-all-matching-cvs__item-content-company mr-6">
            <v-label class="page-xranking-all-matching-cvs__header-label">
              {{ $t('COMMON.LABELS.COMPANY') }}
            </v-label>
          </v-list-item-content>

          <v-list-item-content class="page-xranking-all-matching-cvs__item-content-office mr-3">
            <v-label>
              {{ $t('COMMON.LABELS.OFFICE') }}
            </v-label>
          </v-list-item-content>

          <v-list-item-content class="page-xranking-all-matching-cvs__item-content-score">
            <v-label>
              {{ $t('COMMON.LABELS.SCORE') }}
            </v-label>
          </v-list-item-content>

          <v-list-item-action class="page-xranking-all-matching-cvs__header-actions-label ml-0">
            <v-list-item-content class="ml-4">
              <v-label>
                {{ $t('COMMON.LABELS.CREATED_AT') }}
              </v-label>
            </v-list-item-content>
            <v-list-item-content class="ml-4" :style="{width: '80px'}">
              <v-label>
                {{ $t('COMMON.LABELS.ACTIONS') }}
              </v-label>
            </v-list-item-content>
          </v-list-item-action>
        </div>
      </template>
      <template v-slot:item-list="item">
        <v-list-item-content class="page-xranking-all-matching-cvs__item-content-description">
          <v-list-item-title class="primary--text">
            <strong class="page-xranking-all-matching-cvs__item-description">{{ item.description }}</strong>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-content class="page-xranking-all-matching-cvs__item-content-company mr-6">
          <v-list-item-title class="primary--text">
            <strong class="page-xranking-all-matching-cvs__item-company">{{ item.company }}</strong>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-content class="page-xranking-all-matching-cvs__item-content-office mr-3">
          <v-list-item-title class="primary--text">
            <strong class="page-xranking-all-matching-cvs__item-office">{{ item.source_name }}</strong>
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-content class="page-xranking-all-matching-cvs__item-content-score mr-3">
          <div class="d-flex align-center">
            <span
              v-for="(_, index) in 5"
              :key="`block-${index}`"
              class="page-xranking-all-matching-cvs__score-item"
              :style="backgroundScore(index, item.score)"
            />
          </div>
        </v-list-item-content>

        <v-list-item-action class="page-xranking-all-matching-cvs__item-date ml-3 date">
          {{ $moment(item.created_at).fromNow() }}
        </v-list-item-action>

        <v-list-item-action>
          <div>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  class="px-1"
                  style="min-width: 36px !important"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="goToMatching(item)"
                  color="accent"
                >
                  <v-icon>mdi-magnify-scan</v-icon>
                </v-btn>
              </template>
              {{ $t('COMMON.MATCHING') }}
            </v-tooltip>
            <v-tooltip bottom transition="none">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="ml-2"
                  @click.stop="loadFileDetails(item.id)"
                >
                  <v-icon>mdi-download</v-icon>
                </v-btn>
              </template>
             {{ $t('COMMON.DOWNLOAD') }}: {{ $t('COMMON.NOT_AVAILABLE') }}
            </v-tooltip>
          </div>
        </v-list-item-action>
      </template>
    </List>

    <div
      v-if="!cvMatch.length && noData"
      class="page-xranking-all-matching-cvs__no-data mt-10"
    >
      {{ $t("COMMON.NO_DATA") }}
    </div>

  </div>
</template>

<script>
import { mapState, mapGetters,  mapActions } from "vuex"
import PageHeader from '@/components/PageHeader'
import List from '@/components/List'
import Loading from '@/components/Loading'
import calculateExperience from '@/utils/calculateExperience'
import lineJoint from '@/utils/lineJoint'

export default {
  name: "PageXrankingAllMatchingCVs",
  components: {
    PageHeader,
    List,
    Loading,
  },
  data: (_this) => ({
    loading: false,
    offset: 0,
    limit: 10,
    page: 1,
    cvNative: null,
    colors: ['#FF4E11', '#FF8E15', '#FAB733', '#ACB334', '#69B34C'],
    color: 'rgba(0,0,0, .08)',
    noData: false,
  }),
  computed: {
    ...mapState('user', {
      userOfficeId: state => state.user.office_id,
    }),

    ...mapGetters({
      cv: 'cv/getCV',
      fullName: 'cv/getFullName',
      cvMatch: 'cv/getCVMatch',
      personalInfoOnMatch: 'cv/getPersonalInfoOnMatch',
      token: 'user/getToken',
    }),

    cvId() {
      return this.$route.params.id
    },

    currentPageData() {
      const start = (this.page - 1) * this.limit
      const end = start + this.limit
      const cloneCVMatch = JSON.parse(JSON.stringify(this.cvMatch))

      return cloneCVMatch.slice(start, end)
    },

    experience() {
      return calculateExperience(this.personalInfoOnMatch.experiences)
    },
  },
  mounted() {
    this.validateRaOrg()
  },
  methods: {
    ...mapActions({
      addCV: 'cv/addCV',
      addCVMatch: 'cv/addCVMatch',
      addJobMatch: 'job/addJobMatch',
    }),

    goToItem(item) {
      item = item ? item : this.selected[0]
      this.$router.push(`/xranking/offers/${item.id}`)
    },

    validateRaOrg() {
      this.$services.xusers_ra.organization.describe()
        .then((response) => {
          if (response.data.organization.id !== this.userOfficeId) {
            this.$store.dispatch('messenger/add', {type: 'warning', code: 'XRANKING.MISSING_OFFICE_ORG_MAPPING',
              message: `Office ${this.userOfficeId} does not have a corresponding xusers organization mapped`, time: 5000 });
          } else {
            //load cv info
            this.loadCV()
          }
        })
        .catch(e => {
          let message = `Error validating organization for office ${this.userOfficeId}`
          if (e.error && e.error.code && e.error.message) {
            message = `${e.error.code} - ${e.error.message}`
          }
          this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.ORGANIZATION_ERROR',
            message, time: 5000 })
        })
    },

    loadCV() {
      this.$services.xranking_ra
        .v2_cvs.getById(this.cvId)
        .then(({ data }) => {
          const { cv } = data;
          this.cvNative = data;
          this.addCV(cv);
           //retrieve offers that match the cv
          this.searchItems()
        })
    },

    changePage(page) {
      this.page = page
      this.offset = this.page - 1
      this.searchItems()
    },

    searchItems() {
      this.loading = true
      const { id } = this.cv
      const limit = 50

      this.$services.xmatching_ra.v2_cvs.match(id, limit)
        .then(({ data }) => {
          this.addCVMatch(data || [])
        })
        .finally(() => {
          this.loading = false
          this.noData = true
        })

      this.offset = this.limit * this.page - 1
    },

    backgroundScore(i, score) {
      return {
        backgroundColor: i < Math.round(score / 20) ? this.colors[i] : this.color,
      }
    },

    goToMatching(item) {
      const { id } = item ? item : this.selected[0]
      this.addJobMatch([])
      this.$router.push(`/xranking/matching/offers/${id}`)
    },

    backToList() {
      this.$router.push('/xranking/cvs/')
    },

    education(school_name, level) {
      return lineJoint(school_name, level)
    },

    location(city, country) {
      return lineJoint(city, country)
    },

    async loadFileDetails(fileId) {
      let data

      try {
        const { data: responseData } = await this.$services.xxlsfiles_ra.file.detailes(fileId)
        data = responseData
      } catch({ error: { message, details: { Error }}}) {
        const msg = `${message}. ${Error || ''}`
        this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.SERVER_ERROR',
          message: msg, time: 5000 })
      }

      if (data.status === 'processed') {
        const link = document.createElement('a')
        const downloadFileName = `${data.original_filename.split('.').shift()}.xlsx`
        const windowUrl = window.URL || window.webkitURL
        const req = new window.XMLHttpRequest()
        req.responseType = 'blob'
        req.open(
          'GET', `${process.env.VUE_APP_KONG_URL}/xranking/v2/jobs/${fileId}?exportFormat=xlsx&exportLang=${this.$t('COMMON.ANOTHER.LANG')}`
        )
        req.setRequestHeader('authorization', `Bearer ${this.token}`)
        req.onload = function onload() {
          const blob = req.response // Note: not oReq.responseText
          const url = windowUrl.createObjectURL(blob)
          link.setAttribute('href', url)
          link.setAttribute('download', downloadFileName)
          link.click()
          windowUrl.revokeObjectURL(url)
        }
        req.send()
      }
    },
  },
}
</script>

<style lang="scss">
.page-xranking-all-matching-cvs {
  position: relative;

  .v-btn.v-btn--icon.v-btn--round.theme--light.v-size--x-large {
    background-color: $color-dark-blue;
    color: $color-white;

    &:hover {
      opacity: 0.6;
    }
  }

  .list-actions {
    margin-bottom: 85px;
    
    .v-btn--icon,
    .theme--light.v-btn.v-btn--has-bg {
      background-color: $color-dark-blue;
      color: $color-white;

      &:hover {
        background-color: $color-white;
        color: $color-dark-blue;
      }
    }
  }

  .page-xranking-all-matching-cvs__item-description,
  .page-xranking-all-matching-cvs__item-office,
  .page-xranking-all-matching-cvs__item-date {
    white-space: pre-wrap;
  }

  .page-xranking-all-matching-cvs__item-content-company {
    flex: unset;
    min-width: 200px;
    max-width: 200px;
  }

  .page-xranking-all-matching-cvs__item-content-office,
  .page-xranking-all-matching-cvs__item-content-score {
    flex: unset;
    min-width: 165px;
    max-width: 165px;
  }
}

.page-xranking-all-matching-cvs__detail-info {
  cursor: pointer;

  .v-icon {
    color: $color-dark-blue;
  }
}

.page-xranking-all-matching-cvs__personal-info {
  font-size: 16x;
  line-height: 30px;
  color: $color-grey-middle;
}

.page-xranking-all-matching-cvs__position {
  color: $color-black;
}

.page-xranking-all-matching-cvs__personal-info-icon {
  color: $color-dark-blue;
}

.page .page-xranking-all-matching-cvs__list-header {
  display: flex;
  flex-direction: row;
}

.page-xranking-all-matching-cvs__header-actions-label {
  display: flex;
  flex-direction: row;
}

.page-xranking-all-matching-cvs__no-data {
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: $color-dark-blue;
}

.page-xranking-all-matching-cvs__score-item {
  min-width: 30px;
  min-height: 15px;
  margin: 1px;

  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
</style>
