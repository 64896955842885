<template>
  <div
    :class="['input-drop-zone rounded', hover ? 'hover' : '']"
    @click="$refs.file.click()"
    @drop.prevent="uploadFile"
    @dragenter="dragTo"
    @dragover.prevent="dragTo"
    @dragleave="dragTo"
  >
    <input
      type="file"
      ref="file"
      name="file"
      class="input-drop-zone-input"
      :multiple="multi"
      @change="uploadFile"
    >
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'Uploader',
  data: () => ({
    hover: false,
    file: [],
  }),
  methods: {
    uploadFile(event) {
      this.hover = false
      const file = event.type !== 'drop' ? this.$refs.file.files[0] || {} : event.dataTransfer.files[0] || {}

      if (!file) return
      this.$emit('input', file)
    },

    dragTo(event) {
      if (event.type !== 'dragleave') {
        this.hover = true
        return
      }

      this.hover = false
    },
  },
}
</script>
