<template>
  <div class="page page-xranking-all-upload-cv">
    <PageHeader
      :back="`/xranking/${partPathRoute}/`"
      :icon="$t(`${headerTitle}.ICON`)"
      :title="$t(`${headerTitle}.TITLES`)"
      class="pt-2"
    >
    </PageHeader>

    <Loading :value="loading" />

    <v-row class="ml-4 pt-4 mr-4 height-100">
      <v-col cols="12" class="page-xranking-all-upload-cv__wrapper pl-0 pr-0 pt-0">
        <div class="page-xranking-all-upload-cv__wrapper-inner height-100 ">
          <ranking-dropzone
            v-model="files"
            max-length="1"
            type-dropzone="upload"
            acceptFileType="application/pdf"
            @change="checkFile"
            :mode="mode"
            :validFile="validFile"
            module="xranking-matching"
          >
            <template v-slot:title>
              <p class="page-xranking-all-upload-cv__title mb-4">{{ title }}</p>
            </template>
            <template v-slot:button>
              <v-btn
                depressed
                large
                color="success"
                class="mt-5"
                @click="uploadFile"
              >
                {{ titleParseButton }}
              </v-btn>
            </template>
          </ranking-dropzone>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import PageHeader from '@/components/PageHeader'
import Uploader from '@/components/Uploader'
import Loading from '@/components/Loading'
import RankingDropzone from '@/components/RankingDropzone'

export default {
  components: {
    PageHeader,
    Uploader,
    Loading,
    RankingDropzone,
  },
  data() {
    return {
      files: [],
      fileSize: null,
      maxFileSize: 31457280,
      allowedFileExtension: ['pdf', 'png', 'jpg', 'docx'],
      loading: false,
      mode: '',
      timer: null,
      isParsedAttempts: 0,
      validFile: false,
    }
  },
  mounted() {
    const separatedRoutePath = this.$route.path.split('/')
    this.mode = separatedRoutePath[separatedRoutePath.length - 1]
  },
  computed: {
    ...mapGetters({
      officeId: 'user/getOfficeId',
    }),

    notificationStyle() {
      switch(this.notificationType) {
        case 'error': 
          return '#D81B60'
        default:
          return ''
      }
    },

    headerTitle() {
      return this.mode === 'cv' ? 'CV' : 'JOB'
    },

    title() {
      return this.mode === 'cv'
        ? this.$t('COMMON.LABELS.UPLOAD_TITLE_CV')
        : this.$t('COMMON.LABELS.UPLOAD_TITLE_JOB')
    },

    partPathRoute() {
      return this.mode === 'cv' ? 'cvs' : 'offers'
    },

    modeUploader() {
      return this.mode === 'cv' ? 'cv' : 'job'
    },

    titleParseButton() {
      return this.mode === 'cv' ? this.$t('INSIGHT.PARSE_BUTTON_CV') : this.$t('IMPACT.BUTTONS.PARSE_BUTTON_JOB')
    }
  },
  methods: {
    ...mapActions({
      addCVMatch: 'cv/addCVMatch',
    }),

    onUploadError(e) {
        this.$store.dispatch('messenger/add', {type: 'error', code: e.code ? e.code : 'OOPS', message: "XRANKING.UPLOAD_ERROR", time: 5000 });
        this.loading = false;
    },

    async uploadFile() { 
      const { name } = this.files[0]
      const type = this.mode === 'cv' ? 'cv' : 'job'
      const formData = new FormData()
      formData.append('office_id', this.officeId)
      formData.append(type, this.files[0], name)

        try {
          this.loading = true
          const { data, type } = await this.$services.xfiles_ra.file.upload(formData)

          if (!data && !data.fileId || type !== 'success') {
            this.onUploadError()
            return
          }

          this.isParsedAttempts = 0
          this.isParsed(data.fileId)
        } catch(e) {
          this.onUploadError(e)
        }
    },

    async isParsed(fileId) {
      const mode = this.mode === 'cv' ? 'cvs' : 'offers'
      
      if (this.timer) clearInterval(this.timer)

      try {
        const { data } = await this.$services.xfiles_ra.file.isParsed(fileId)
        this.isParsedAttempts++

        if (data && data.status && data.status !== 'processed') {
          if (data.status === "errored" || this.isParsedAttempts > 10) {
            this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.FILE_PARSE_ERROR', message: data.status, time: 5000 })
            this.loading = false;
            return
          }
          else {
            this.timer = setInterval(() => this.isParsed(fileId), 3000)
            return
          }
        }

        if (mode === 'cvs') this.addCVMatch([])
        this.loading = false
        this.$router.push(`/xranking/${mode}/${fileId}`)
      } catch({ error: { message }}) {
        this.$store.dispatch('messenger/add', {type: 'error', code: 'XRANKING.FILE_PARSE_ERROR', message, time: 5000 })
        this.loading = false
      }
    },

    checkIsExtensionValid(name) {
      const namesArr = name.split('.')
      const fileExtension = namesArr[namesArr.length - 1]

      return this.allowedFileExtension.includes(fileExtension)
    },

    checkFile () {
      let fileSize
      if (!this.files.length) return
      this.files.forEach(file => {
        const { size } = file
        fileSize = size ? size : 0
      })

      if (fileSize > 0 && fileSize < this.maxFileSize) {
        this.validFile = true;
        return;
      }

      this.$store.dispatch('messenger/add', 
        { type: 'error', code: 'DEFAULT_MESSAGE', message: this.$t('MESSAGE.XRANKING.UNSUPPORTED_SIZE'), time: 5000 });

      this.validFile = false;
    },
  },
}
</script>

<style lang="scss">
.page-xranking-all-upload-cv {
  position: relative;

  .input-drop-zone__active {
    display: flex;
    justify-content: center;
  }
}

.page-xranking-all-upload-cv__wrapper {
  position: relative;
}

.page-xranking-all-upload-cv__wrapper-inner {
  margin-top: -14px;
}

.page-xranking-all-upload-cv__title {
  text-align: center;
  font-size: 16px;
  line-height: 24px;
}

.page-xranking-all-upload-cv__title-mode {
  text-transform: capitalize;
}

.page-xranking-all-upload-cv__uploader {
  display: flex;
  flex-direction: row;
  border: 1px dashed $color-dark-blue;
}

.page-xranking-all-upload-cv__select-file {
  font-size: 20px;
  line-height: 29px;
  color: $color-grey;
}

.page-xranking-all-upload-cv__dark-blue {
  color: $color-dark-blue;
}
</style>
